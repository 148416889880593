import { createMuiTheme, responsiveFontSizes, Theme } from '@material-ui/core'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { Shadows } from '@material-ui/core/styles/shadows'
import fontStyles from './fontStyles'
import colors from './colors'

const breakpoints = createBreakpoints({})

const DefaultFontSize = 16

const LocalTheme: Theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 860,
            lg: 1440,
            xl: 1920,
        },
    },
    overrides: {
        MuiGrid: {
            root: {
                '&.FooterLogoWrapper .FooterLogo': {
                    height: 64,
                    maxWidth: 120,
                },
                '&.HeaderLogoWrapper .HeaderLogo': {
                    height: 64,
                    maxWidth: 210,
                },
            },
        },
        MuiAppBar: {
            root: {
                padding: '24px 28px 27px 28px',
                borderBottom: `5px solid ${colors.midGray}`,
                boxShadow: 'none',
            },
            colorPrimary: {
                backgroundColor: colors.appBarBackground,
            },
        },
        MuiBackdrop: {
            root: {
                '&.LoadingSpinner': {
                    zIndex: 1400,
                    color: '#fff',
                },
            },
        },
        MuiCircularProgress: {
            root: {
                '&.LoadingSpinner': {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                },
            },
        },
        MuiFormGroup: {
            root: {
                [breakpoints.down('xs')]: {
                    '&.phoneBookEntryAddr': {
                        flexDirection: 'column',
                    },
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: '5px',
                padding: '14px 38px !important',
                lineHeight: 1.25,
                [breakpoints.down('xs')]: {
                    '&.mt1': {
                        marginTop: 15,
                    },
                },
            },
            containedPrimary: {
                '& .MuiButton-label': {
                    ...fontStyles.CondensedBold,
                    color: colors.white,
                },
                '&$disabled': {
                    backgroundColor: colors.lighterMain,
                },
            },
            containedSecondary: {
                '& .MuiButton-label': {
                    ...fontStyles.CondensedBold,
                    color: colors.white,
                },
            },
            outlined: {
                border: `2px solid ${colors.darkGray}`,
                '& .MuiButton-label': {
                    ...fontStyles.CondensedBold,
                },
                '&.EditButton': {
                    padding: '6px 16px !important',
                    border: `2px solid ${colors.lightDarkerGray}`,
                    color: colors.lightDarkerGray,
                    '&$disabled': {
                        border: `2px solid ${colors.lightGray}`,
                        color: colors.lightGray,
                    },
                },
                '&.Small': {
                    padding: '2px !important',
                    minWidth: 'auto',
                    '& .MuiButton-startIcon': {
                        marginRight: 0,
                    },
                },
            },
            outlinedSecondary: {
                padding: '16px 20px !important',
                '&:hover': {
                    border: '1.5px colors ' + colors.main,
                },
            },
            sizeLarge: {
                fontSize: `1.33rem`,
            },
            text: {
                padding: '16px 8px !important',
                '& .MuiButton-label': {
                    textDecoration: 'underline',
                    textTransform: 'none',
                },
            },
            label: {
                textAlign: 'center',
            },
            startIcon: {
                marginLeft: 0,
            },
        },
        MuiButtonBase: {
            root: {
                '&.NavButtonBase': {
                    width: '100%',
                    height: '100%',
                    padding: '28px 32px',
                },
                '&.AutocompleteButtonBase': {
                    width: '100%',
                    justifyContent: 'flex-start',
                    height: '100%',
                    padding: '6px 16px',
                },
            },
        },
        MuiFilledInput: {
            root: {
                borderRadius: '5px',
                '&$disabled': {
                    backgroundColor: colors.white,
                    color: colors.black,
                    '&:after': {
                        border: `1px solid ${colors.midGray}`,
                        borderBottom: `1px solid ${colors.midGray}`,
                        opacity: 1,
                    },
                },
            },
            marginDense: {
                '& .MuiSelect-iconFilled': {
                    right: '-8px',
                },
                '&:after': {
                    border: '0px',
                    borderBottom: '0px',
                },
            },
            input: {
                fontSize: DefaultFontSize * 1.125,
                paddingLeft: 20,
            },
            inputMarginDense: {
                fontSize: DefaultFontSize * 0.875,
                paddingBottom: 4,
                paddingLeft: 6,
                paddingTop: 18,
            },
            multiline: {
                paddingLeft: 20,
            },
            underline: {
                '&:after': {
                    transform: 'scaleX(1)',
                    transition: 'opacity 250ms, transform 250ms',
                    borderRadius: '5px',
                    height: '100%',
                    border: `1px solid ${colors.midGray}`,
                    borderBottom: `1px solid ${colors.midGray}`,
                    opacity: 1,
                },
                '&:before': {
                    borderBottom: 0,
                },
                '&$error:after': {
                    opacity: 1,
                    border: `2px solid ${colors.warning}`,
                    borderBottom: `2px solid ${colors.warning}`,
                },
                '&$focused:after': {
                    opacity: 1,
                    border: `2px solid ${colors.focusedField}`,
                    borderBottom: `2px solid ${colors.focusedField}`,
                    transition: 'opacity 250ms, transform 250ms',
                },
                '&:hover:before': {
                    borderBottom: 0,
                },
            },
        },
        MuiInputLabel: {
            filled: {
                overflow: 'hidden',
                minHeight: 20,
                textOverflow: 'ellipsis',
                right: '18%',
                [breakpoints.down('xs')]: {
                    right: '24%',
                },
                whiteSpace: 'nowrap',
                transform: 'translate(20px, 20px) scale(1.125)',
                '&.DropdownLabel': {
                    right: '20%',
                    [breakpoints.down('xs')]: {
                        right: '34%',
                    },
                },
                '&$shrink': {
                    right: '-22%',
                    '&.DropdownLabel': {
                        right: '18%',
                        [breakpoints.down('xs')]: {
                            right: '-12%',
                            '&.MuiInputLabel-marginDense': {
                                right: '-70%',
                            },
                        },
                    },
                    [breakpoints.down('xs')]: {
                        right: '-12%',
                    },
                    transform: 'translate(20px, 10px) scale(0.75)',
                    '&.MuiInputLabel-marginDense': {
                        right: '-90%',
                        transform: 'translate(6px, 5px) scale(0.5)',
                    },
                },
                '&.MuiInputLabel-marginDense': {
                    right: '-11%',
                    transform: 'translate(6px, 12px) scale(0.875)',
                    '&.DropdownLabel': {
                        right: '2%',
                        '@media (max-width: 400px)': {
                            right: '12%',
                        },
                    },
                },
            },
        },
        MuiFormControlLabel: {
            root: {
                [breakpoints.down('xs')]: {
                    marginRight: 0,
                },
            },
        },
        MuiFormLabel: {
            root: {
                color: colors.labelColor,
                '&$disabled': { color: colors.labelColor },
                '&$focused': {
                    color: colors.labelColor,
                },
                '&$error': {
                    color: colors.labelColor,
                },
            },
        },
        MuiList: {
            root: {
                '&.ErrorBox': {
                    padding: 20,
                    marginTop: 40,
                    width: '100%',
                    borderRadius: 4,
                    backgroundColor: colors.lightWarning,
                    '& .MuiListItem-root': {
                        padding: 0,
                        '& .MuiListItemIcon-root': {
                            color: colors.warning,
                            fontSize: 14,
                            minWidth: 16,
                            '& .MuiSvgIcon-root': {
                                height: '7px',
                                width: '7px',
                            },
                        },
                        '& .MuiListItemText-root': {
                            color: colors.warning,
                        },
                    },
                },
            },
        },
        MuiListSubheader: {
            root: {
                '&.ErrorBoxSubheader': {
                    color: colors.warning,
                },
            },
        },
        MuiRadio: {
            root: {
                color: colors.midDarkerGray,
                // backgroundColor: colors.white,
            },
        },
        MuiSelect: {
            root: {
                '&:not(.MuiFilledInput-inputMarginDense) .MuiTypography-body1': {
                    height: '1.1876em',
                    fontSize: DefaultFontSize * 1.125,
                },
                '&.MuiFilledInput-inputMarginDense': {
                    '&.MuiSelect-selectMenu': {
                        height: '1.1876em',
                    },
                    '& .MuiTypography-body1': {
                        lineHeight: '1.1876em',
                        fontSize: DefaultFontSize * 0.875,
                    },
                    '& p': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                },
            },
        },
        MuiTableSortLabel: {
            icon: {
                '&.MuiSvgIcon-root': {
                    color: `${colors.labelColor} !important`,
                    width: 18,
                    height: 18,
                    margin: 0,
                },
            },
        },
        MuiTableRow: {
            root: {
                '&.Mui-selected': {
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: `${colors.main}14`,
                    },
                },
            },
        },
        MuiTextField: {
            root: {
                '&.SearchBarInput': {
                    '& .MuiAutocomplete-inputRoot': {
                        '& input': {
                            paddingLeft: '13px !important',
                        },
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
                        borderColor: colors.lightDarkerGray,
                        paddingLeft: '16px',
                    },
                    '&:focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1px',
                    },
                    // '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    //     borderColor: colors.lightDarkerGray,
                    //     borderWidth: '1px',
                    // },
                },
                // paddingLeft: '16px',

                '&.HaveOptions': {
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.lightDarkerGray,
                        borderRadius: '24px',
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                        borderBottom: 'none',
                        // borderWidth: '1px',
                    },
                },
            },
        },
        MuiTypography: {
            root: {
                '&.CollapsableTitle': {
                    textAlign: 'left',
                    [breakpoints.down('xs')]: {
                        textAlign: 'center',
                    },
                },
            },
        },
    },
    shadows: Array(25).fill('none') as Shadows,
    typography: {
        h1: { ...fontStyles.Title },
        h2: { ...fontStyles.Title2 },
        h3: { ...fontStyles.Title3 },
        subtitle1: { ...fontStyles.Subtitle },
        body1: { ...fontStyles.NormalText },
        body2: { ...fontStyles.SmallText },
        button: { ...fontStyles.ButtonText },
    },
    palette: {
        action: {
            disabled: colors.lightGray,
            disabledBackground: colors.lightGray,
        },
        primary: {
            main: colors.main,
            light: colors.lightGray,
            contrastText: colors.darkGray,
        },
        secondary: {
            main: colors.button,
        },
        text: {
            primary: colors.black,
            secondary: colors.white,
        },
    },
})

export const ModifiedTheme = responsiveFontSizes(LocalTheme)
